
/* Facebook Post Styles */
.robopost-facebook-post-preview-post-container {
  background-color: white;
  max-width: 400px; /* Restrict maximum width */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 0 auto 20px auto;
  padding: 20px;
}

.robopost-facebook-post-preview-post-header {
  display: flex;
  align-items: center;
}

.robopost-facebook-post-preview-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.robopost-facebook-post-preview-user-info {
  display: flex;
  flex-direction: column;
}

.robopost-facebook-post-preview-name {
  font-weight: bold;
  font-size: 16px;
}

.robopost-facebook-post-preview-time {
  font-size: 12px;
  color: #65676b;
}

.robopost-facebook-post-preview-post-content {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.robopost-facebook-post-preview-post-image img,
.robopost-facebook-post-preview-post-video video {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.robopost-facebook-post-preview-post-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  gap: 20px;
}

.robopost-facebook-post-preview-like-comment {
  display: flex;
  align-items: center;
  color: #65676b;
}

.robopost-facebook-post-preview-like-comment i {
  margin-right: 5px;
}
.robopost-facebook-post-preview-like-comment i,
.robopost-facebook-post-preview-like-comment svg {
  margin-right: 5px; /* Adds space between the icon and the text */
}

.robopost-facebook-post-preview-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.robopost-facebook-post-preview-action {
  padding: 10px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: #65676b;
  cursor: pointer;
}

.robopost-facebook-post-preview-action:hover {
  background-color: #f0f2f5;
  border-radius: 5px;
}

.robopost-facebook-post-preview-action i {
  margin-right: 5px;
}

/* Responsive adjustments for Facebook post styles */
@media (min-width: 768px) {
  .robopost-facebook-post-preview-post-container {
    //padding: 20px;
  }

  .robopost-facebook-post-preview-profile-picture {
    width: 50px;
    height: 50px;
  }

  .robopost-facebook-post-preview-name {
    font-size: 18px;
  }

  .robopost-facebook-post-preview-time {
    font-size: 14px;
  }

  .robopost-facebook-post-preview-post-footer,
  .robopost-facebook-post-preview-actions {
    justify-content: flex-start;
  }

  .robopost-facebook-post-preview-action {
    width: auto;
    margin-right: 20px;
  }
}

.robopost-facebook-custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 40px; /* Ensures the element is a perfect circle */
  height: 40px; /* Ensures the element is a perfect circle */
  border-radius: 50%; /* Makes it a circle */
  display: flex; /* Centers the icon inside the circle */
  justify-content: center; /* Centers the icon horizontally */
  align-items: center; /* Centers the icon vertically */
  cursor: pointer;
}

.robopost-facebook-custom-arrow-prev {
  left: 10px;
}

.robopost-facebook-custom-arrow-next {
  right: 10px;
}

.robopost-facebook-reel-preview-image-carousel {
  position: relative;
}

.robopost-facebook-reel-preview-image-carousel img {
  width: 100%;
  display: block;
}

.robopost-facebook-reel-container {
  font-family: Arial, sans-serif;
  background-color: #000;
  color: #fff;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.robopost-facebook-reel-video-container {
  position: relative;
  width: 100%;
  background-color: #222;
  height: 400px;
}

.robopost-facebook-reel-video,
.robopost-facebook-reel-image,
.robopost-facebook-reel-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.robopost-facebook-reel-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #888;
}

.robopost-facebook-reel-user-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.robopost-facebook-reel-user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.robopost-facebook-reel-username {
  font-weight: bold;
}

.robopost-facebook-reel-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.robopost-facebook-reel-action-buttons {
  display: flex;
}

.robopost-facebook-reel-action-button {
  margin-right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.robopost-facebook-reel-caption {
  margin-top: 10px;
  font-size: 14px;
}


/* TikTok Post Styles */
.robopost-tiktok-post-preview-tiktok-post-container {
  position: relative;
  background-color: #000;
  max-width: 400px; /* Adjust max width for larger screens */
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto 20px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.robopost-tiktok-post-preview-post-content {
  position: relative;
  color: white;
  padding: 20px;
}

.robopost-tiktok-post-preview-profile-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.robopost-tiktok-post-preview-profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid white;
}

.robopost-tiktok-post-preview-user-info {
  display: flex;
  flex-direction: column;
}

.robopost-tiktok-post-preview-name {
  font-weight: bold;
  font-size: 16px;
}

.robopost-tiktok-post-preview-media-content img,
.robopost-tiktok-post-preview-media-content video {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.robopost-tiktok-post-preview-post-text {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.robopost-tiktok-post-preview-music {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
}

.robopost-tiktok-post-preview-music svg {
  margin-right: 5px;
}

.robopost-tiktok-post-preview-side-icons {
  position: absolute;
  right: 20px;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.robopost-tiktok-post-preview-icon {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  margin-right: 2px;
}

.robopost-tiktok-post-preview-icon span {
  display: block;
  font-size: 14px;
  margin-top: 5px;
}

.robopost-tiktok-post-preview-icon i,
.robopost-tiktok-post-preview-icon svg {
  margin-bottom: 5px; /* Adds space between the icon and the text below */
}

/* Responsive adjustments for TikTok post styles */
@media (min-width: 768px) {
  .robopost-tiktok-post-preview-profile-picture {
    width: 60px;
    height: 60px;
  }

  .robopost-tiktok-post-preview-name {
    font-size: 18px;
  }

  .robopost-tiktok-post-preview-post-text {
    font-size: 16px;
  }

  .robopost-tiktok-post-preview-side-icons {
    right: 30px;
    bottom: 120px;
  }

  .robopost-tiktok-post-preview-icon {
    font-size: 26px;
  }

  .robopost-tiktok-post-preview-icon span {
    font-size: 16px;
  }
}

/* Pinterest Post Styles */
.pinterest-post-container {
  max-width: 400px; /* Adjust max width for larger screens */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0 auto 20px auto;
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
}

.pinterest-post-image-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%; /* This creates a 1:1 aspect ratio for the image */
  background-color: #f0f0f0;
}

.pinterest-post-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.pinterest-post-container:hover .pinterest-post-image {
  transform: scale(1.05); /* Zoom effect on hover */
}

.pinterest-post-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.pinterest-post-container:hover .pinterest-post-overlay {
  opacity: 1;
}

.pinterest-post-actions {
  display: flex;
  gap: 10px;
  color: white;
  font-size: 20px;
}

.pinterest-post-details {
  padding: 10px;
  font-size: 14px;
}

.pinterest-post-description {
  margin: 0;
  color: #333;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pinterest-post-user {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pinterest-post-user-profile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

/* Responsive adjustments for small screens */
@media (max-width: 400px) {
  .pinterest-post-container {
    margin: 0 10px;
    max-width: 100%; /* Full width on small screens */
  }

  .pinterest-post-description {
    font-size: 12px;
  }

  .pinterest-post-user-profile {
    width: 25px;
    height: 25px;
  }

  .pinterest-post-actions {
    font-size: 18px; /* Slightly smaller icons on small screens */
  }
}

/* Responsive adjustments for tablets */
@media (min-width: 601px) and (max-width: 1024px) {
  .pinterest-post-container {
    max-width: 100%;
    margin: 10px;
  }

  .pinterest-post-image-wrapper {
    padding-top: 100%; /* Keep 1:1 ratio on tablets */
  }

  .pinterest-post-description {
    font-size: 14px;
  }

  .pinterest-post-user-profile {
    width: 30px;
    height: 30px;
  }
}

/* Responsive adjustments for larger screens */
@media (min-width: 1025px) {
  .pinterest-post-container {
    max-width: 100%;
    margin: 10px;
  }

  .pinterest-post-image-wrapper {
    padding-top: 75%; /* Adjust aspect ratio for larger screens */
  }

  .pinterest-post-description {
    font-size: 16px;
  }

  .pinterest-post-user-profile {
    width: 40px;
    height: 40px;
  }

  .pinterest-post-actions {
    font-size: 22px; /* Larger icons on large screens */
  }
}

/* Pinterest-style grid layout for larger screens */
.pinterest-post-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid with minimum column width */
  gap: 20px; /* Space between posts */
  padding: 20px;
}


/* LinkedIn Post Styles */
.robopost-linkedin-post-preview-post-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 auto 20px auto;
  max-width: 400px; /* Adjust max width for larger screens */
  font-family: Arial, sans-serif;
}

.robopost-linkedin-post-preview-post-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.robopost-linkedin-post-preview-profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.robopost-linkedin-post-preview-user-info {
  display: flex;
  flex-direction: column;
}

.robopost-linkedin-post-preview-name {
  font-weight: bold;
  font-size: 16px;
}

.robopost-linkedin-post-preview-title {
  font-size: 14px;
  color: #777;
}

.robopost-linkedin-post-preview-time {
  font-size: 12px;
  color: #999;
}

.robopost-linkedin-post-preview-post-content {
  font-size: 14px;
  margin: 10px 0;
  line-height: 1.5;
  color: #333;
}

.robopost-linkedin-post-preview-post-image img {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.robopost-linkedin-post-preview-post-video video {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

/* Custom carousel arrows */
.robopost-linkedin-post-custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.robopost-linkedin-post-custom-arrow-prev {
  left: 10px;
}

.robopost-linkedin-post-custom-arrow-next {
  right: 10px;
}

/* Engagement Stats */
.robopost-linkedin-post-preview-engagement-stats {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  color: #555;
  font-size: 14px;
  margin: 10px 0;
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
}

/* Action Buttons */
.robopost-linkedin-post-preview-actions {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
  margin-top: 10px;
}

.robopost-linkedin-post-preview-action {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.2s;
}

.robopost-linkedin-post-preview-action:hover {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.robopost-linkedin-post-preview-action svg {
  margin-right: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .robopost-linkedin-post-preview-post-container {
    padding: 15px;
  }

  .robopost-linkedin-post-preview-profile-picture {
    width: 40px;
    height: 40px;
  }

  .robopost-linkedin-post-preview-name {
    font-size: 15px;
  }

  .robopost-linkedin-post-preview-title {
    font-size: 13px;
  }

  .robopost-linkedin-post-preview-time {
    font-size: 11px;
  }

  .robopost-linkedin-post-preview-action {
    font-size: 13px;
  }
}

/* Instagram Post Styles */
.robopost-instagram-post-preview-container {
  background-color: white;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 20px auto;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
}

.robopost-instagram-post-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
}

.robopost-instagram-post-preview-user-info {
  display: flex;
  align-items: center;
}

.robopost-instagram-post-preview-profile-picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
}

.robopost-instagram-post-preview-username {
  font-weight: 600;
  font-size: 14px;
  color: #262626;
}

.robopost-instagram-post-preview-location {
  font-size: 12px;
  color: #8e8e8e;
  display: block;
}

.robopost-instagram-post-preview-more-options {
  color: #262626;
  font-size: 16px;
}

.robopost-instagram-post-preview-image-carousel {
  position: relative;
}

.robopost-instagram-post-preview-image-carousel img {
  width: 100%;
  display: block;
}

.robopost-instagram-post-video-container {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: #222;
}

.robopost-instagram-post-video,
.robopost-instagram-post-image,
.robopost-instagram-post-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.robopost-instagram-custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
  color: #262626;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.robopost-instagram-custom-arrow-prev {
  left: 10px;
}

.robopost-instagram-custom-arrow-next {
  right: 10px;
}

.robopost-instagram-post-preview-actions {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}

.robopost-instagram-post-preview-action-group {
  display: flex;
  flex-direction: row; /* This ensures the layout is a row */
  gap: 8px; /* Adds 5px gap between flex items */
}

.robopost-instagram-post-preview-action-icon {
  font-size: 24px;
  color: #262626;
  //margin-right: 16px;
}

.robopost-instagram-post-preview-likes {
  font-weight: 600;
  font-size: 14px;
  color: #262626;
  padding: 0 16px 8px;
}

.robopost-instagram-post-preview-caption {
  font-size: 14px;
  color: #262626;
  padding: 0 16px 0;
  line-height: 1.4;
}

.robopost-instagram-post-preview-timestamp {
  font-size: 10px;
  color: #8e8e8e;
  text-transform: uppercase;
  padding: 0 16px 16px;
}



.robopost-instagram-reel-preview-image-carousel {
  position: relative;
}

.robopost-instagram-reel-preview-image-carousel img {
  width: 100%;
  display: block;
}

.robopost-instagram-reel-container {
  font-family: Arial, sans-serif;
  background-color: #000;
  color: #fff;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.robopost-instagram-reel-video-container {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: #222;
}

.robopost-instagram-reel-video,
.robopost-instagram-reel-image,
.robopost-instagram-reel-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.robopost-instagram-reel-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #888;
}

.robopost-instagram-reel-user-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.robopost-instagram-reel-user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.robopost-instagram-reel-username {
  font-weight: bold;
}

.robopost-instagram-reel-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.robopost-instagram-reel-action-buttons {
  display: flex;
}

.robopost-instagram-reel-action-button {
  margin-right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.robopost-instagram-reel-caption {
  margin-top: 10px;
  font-size: 14px;
}

.robopost-instagram-story-container {
  max-width: 400px;
  height: 400px;
  margin: auto;
  position: relative;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.robopost-instagram-story-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgba(0,0,0,0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.robopost-instagram-story-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.robopost-instagram-story-username {
  color: white;
  font-weight: bold;
}

.robopost-instagram-story-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.robopost-instagram-story-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.robopost-instagram-story-text {
  position: absolute;
  color: white;
  font-size: 24px;
  text-align: center;
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
}

.robopost-instagram-story-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.robopost-instagram-story-reply-bar {
  background-color: rgba(255,255,255,0.2);
  border-radius: 20px;
  padding: 10px 15px;
  color: white;
  flex-grow: 1;
  margin-right: 10px;
}

.robopost-instagram-story-send-button {
  background-color: #0095f6;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
}

/* Slick Slider Custom Styles */
.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  color: white;
}

.slick-dots li.slick-active button:before {
  color: white;
}

/* Responsive adjustments */
@media (max-width: 400px) {
  .robopost-instagram-post-preview-container {
    border-left: none;
    border-right: none;
  }
}

/* Twitter Post Styles */
.robopost-twitter-post-container {
  background-color: white;
  max-width: 400px;
  border-bottom: 1px solid #e1e8ed;
  padding: 15px 20px;
  margin: 0 auto 20px auto;
  font-family: 'Helvetica', sans-serif;
}

.robopost-twitter-post-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.robopost-twitter-profile-picture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}

.robopost-twitter-user-info {
  display: flex;
  flex-direction: column;
}

.robopost-twitter-user-name {
  font-weight: bold;
  font-size: 15px;
  color: #14171a;
}

.robopost-twitter-user-handle {
  font-size: 13px;
  color: #657786;
  margin-left: 5px;
}

.robopost-twitter-time {
  font-size: 12px;
  color: #657786;
}

.robopost-twitter-post-content {
  font-size: 15px;
  color: #14171a;
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.robopost-twitter-post-image img,
.robopost-twitter-post-video video {
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
}

.robopost-twitter-post-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  margin-top: 10px;
  font-size: 13px;
  color: #657786;
}

.robopost-twitter-post-footer div {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.robopost-twitter-post-footer div i,
.robopost-twitter-post-footer div svg {
  margin-right: 5px;
}

.robopost-twitter-post-footer div:hover {
  color: #1da1f2;
}

.robopost-twitter-custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.robopost-twitter-custom-arrow-prev {
  left: 10px;
}

.robopost-twitter-custom-arrow-next {
  right: 10px;
}

.robopost-youtube-container {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
}

.robopost-youtube-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.robopost-youtube-video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.robopost-youtube-video-info {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
}

.robopost-youtube-video-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.robopost-youtube-video-stats {
  color: #606060;
  font-size: 14px;
  margin-bottom: 20px;
}

.robopost-youtube-action-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.robopost-youtube-action-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  cursor: pointer;
  color: #606060;
  font-size: 14px;
}

.robopost-youtube-channel-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.robopost-youtube-channel-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}

.robopost-youtube-channel-name {
  font-weight: bold;
}

.robopost-youtube-subscribe-button {
  background-color: #cc0000;
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
}

.robopost-youtube-short-video-container {
  max-width: 375px;
  height: 400px;
  margin: 0 auto;
  background-color: #000;
  position: relative;
  overflow: hidden;
}

.robopost-youtube-short-video-video-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.robopost-youtube-short-video-video-container video {
  max-width: 100%;
  max-height: 100%;
}

.robopost-youtube-short-video-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  color: #fff;
  background: linear-gradient(transparent, rgba(0,0,0,0.7));
}

.robopost-youtube-short-video-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.robopost-youtube-short-video-channel {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.robopost-youtube-short-video-channel img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.robopost-youtube-short-video-actions {
  display: flex;
  justify-content: space-between;
}

.robopost-youtube-short-video-action-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 12px;
}

.robopost-youtube-short-video-action-btn svg {
  margin-bottom: 5px;
}

.robopost-gmb-post-container {
  font-family: 'Roboto', sans-serif;
  max-width: 400px; /* Adjust max width for larger screens */
  margin: 0 auto 20px auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  padding: 1rem;
}
.robopost-gmb-post-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.robopost-gmb-post-avatar {
  margin-right: 0.75rem;
  background-color: #e0e0e0;
  border-radius: 50%;
  padding: 0.25rem;
}
.robopost-gmb-post-username {
  color: #1a73e8;
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0;
}
.robopost-gmb-post-timestamp {
  color: #5f6368;
  font-size: 0.875rem;
  margin: 0;
}
.robopost-gmb-post-content {
  margin-bottom: 1rem;
}
.robopost-gmb-post-text {
  margin-bottom: 1rem;
}
.robopost-gmb-post-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}
.robopost-gmb-post-actions {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  padding-top: 0.75rem;
}
.robopost-gmb-post-action-button {
  display: flex;
  align-items: center;
  color: #5f6368;
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.robopost-gmb-post-action-button:hover {
  background-color: #f1f3f4;
}
.robopost-gmb-post-action-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}
