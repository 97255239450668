@import "customVariables";
// If you want to override variables do it here
@import "variables";

$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "@coreui/coreui-pro/scss/coreui";
@import "@coreui/coreui-pro/scss/themes/dark";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";

// Plugins
@import "calendar";

// Prism.js
@import "examples";

// If you want to add custom CSS you can put it here.
@import "custom";

// blueprint js
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";

// toastify
@import 'react-toastify/dist/ReactToastify.css';

// RC Tooltip
@import 'rc-tooltip/assets/bootstrap_white.css';

// Time Picker
@import 'react-time-picker/dist/TimePicker.css';
@import 'react-clock/dist/Clock.css';
