.dark-theme {
  .list-action-dropdown {
    margin-top: 10px;
    height: 60px;
    background-color: #343a40; /* Dark gray */
    color: #f8f9fa; /* Light color for text to ensure readability on dark background */
    padding: 10px;
  }

  .calendar-nav-icon-double-prev {
    width: 14px !important;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="white"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"/></svg>') !important;
  }

  .calendar-nav-icon-prev {
    width: 10px !important;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="white"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>') !important;
  }

  .calendar-nav-icon-double-next {
    width: 14px !important;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="white"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6 .1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z"/></svg>') !important;
  }

  .calendar-nav-icon-next {
    width: 10px !important;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="white"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>') !important;
  }
}

.list-action-dropdown {
  margin-top: 10px;
  height: 60px;
  background-color: #f8f9fa;
  padding: 10px;
}

/** Common **/
.list-style-none {
  list-style: none;
}

.form-switch-xl {
  .form-check-input {
    width: 3em;
  }

  .form-check-label {
    margin-left: 0.5rem;
  }
}

.btn-success {
  color: #FFFFFF;

  &:hover {
    color: #FFFFFF;
  }
}

.btn-danger {
  color: #FFFFFF;
}

.sticky-toastr {
  position: fixed !important;
  z-index: 2147483649 !important;
}

.border-card-selected {
  border: 1px solid #2EB85C;
}

.input-group-react-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group-react-select input {
  border-radius: 0 !important;
}

.button-primary-400 {
  background-color: $light-primary-400;
}

.no-under-line {
  text-decoration: none;
}

.sns-icon {
  .twitter {
    color: #000000;
  }

  .twitter-bg {
    background-color: #000000;
  }

  .facebook {
    color: #3b5998;
  }

  .facebook-bg {
    background-color: #3b5998;
  }

  .instagram {
    color: #E1306C;
  }

  .instagram-bg {
    background-color: #E1306C;
  }

  .linkedin {
    color: #0072b1;
  }

  .linkedin-bg {
    background-color: #0072b1;
  }

  .pinterest {
    color: #E60022;
  }

  .pinterest-bg {
    background-color: #E60022;
  }

  .wordpress {
    color: #1B769D;
  }

  .wordpress-bg {
    background-color: #1B769D;
  }

  .tiktok {
    color: #000000;
  }

  .tiktok-bg {
    background-color: #000000;
  }

  .youtube {
    color: #FF0000;
  }

  .youtube-bg {
    background-color: #FF0000;
  }

  .gmb-bg {
    color: #4A89F6;
  }

  .telegram-bg {
    color: #23A2DF;
  }

  .bluesky-bg {
    color: #0285FF;
  }

  .threads-bg {
    background-color: #080808;
  }
}

/** Footer **/
.legal-list {
  padding-inline-start: 0;
  list-style: none;
  font-size: 0.85em;
  line-height: 1.5rem;
  margin-left: 0.25rem;
  margin-bottom: 0;

  li {
    position: relative;
    display: inline-block;
    padding-right: 0.6rem;
  }

  li:not(:first-child) {
    padding-left: 0.4rem;
  }

  li:not(:last-child)::after {
    position: absolute;
    right: 0px;
    content: "|";
  }
}

/**  **/
.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;

  .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    z-index: 9999;
  }
}

.show {
  visibility: visible;
}

.loading-overlay.show {
  opacity: 1;
  z-index: 9998;
}

/** Login/Register/Password **/
.login-wrapper,
.forgot-password-wrapper,
.register-wrapper {
  background-color: $custom-theme-light;

  @media screen and (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }
}

.legal-notice {
  font-size: $font-size-sm;
}

.google-button.btn {
  font-family: Roboto;
  color: #000000;

  &:hover {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    opacity: 0.8;
  }

  svg {
    height: 18px;
    width: 18px;
  }
}

.google-button-register.btn {
  font-family: Roboto;
  color: black;
  background-color: #FFFFFF;
  border-color: #FFFFFF;

  svg {
    height: 18px;
    width: 18px;
  }
}

.card.disabled::before,
.card.disabled::after {
  content: "";
  border-radius: 0.375rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  transition: .3s cubic-bezier(0.45, 0, 0.55, 1);
  opacity: 1;
}

.card.disabled::before {
  background: rgba(0, 0, 0, .5) !important;
  width: 100%;
  height: 100%;
}

/** Connect Channel **/
.connect-channel {
  .card {
    cursor: pointer;
    transition-duration: .3s;
  }

  .card:hover {
    transform: scale(1.02);
  }

  .card.coming-soon {
    cursor: default;
  }

  .card.coming-soon::before,
  .card.coming-soon::after {
    content: "";
    border-radius: 0.375rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    transition: .3s cubic-bezier(0.45, 0, 0.55, 1);
    opacity: 1;
  }

  .card.coming-soon::before {
    background: rgba(0, 0, 0, .5) !important;
    width: 100%;
    height: 100%;
  }

  .coming-soon-text {
    position: absolute;
    top: 3px;
    left: 5px;
    color: white;
    font-size: 0.8em;
  }

  .card-title {
    font-size: 1.2rem;
    height: 35px;
    text-align: center;

    img.youtube {
      height: 100%;
      max-height: 25px;
      width: auto;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-text {
    text-align: center;
    color: #321fdb;
  }

  .card-footer {
    text-align: right;
    border-top: none;
    background-color: inherit;
  }
}

.pre-connect-explanation {
  img {
    padding: 0 1rem;
    max-width: 100%;
    max-height: 350px;
  }
}

.sns-explanation-note {
  font-size: 0.85rem;
  color: rgba(44, 56, 74, 0.95);
}

.bp4-toast-container {
  z-index: 10000;
}

.uploaded-file-container {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  flex: 0 0 auto;
}

.uploaded-file-container-gray-hover:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.uploaded-file-delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1000;
}

.uploaded-file-container:hover .uploaded-file-delete-icon {
  opacity: 1;
}

.uploaded-file-delete-icon > a {
  color: #FFFFFF !important;
  text-decoration: none;
}

.uploaded-file-delete-icon > a:hover {
  opacity: 1 !important;
}

.uploaded-file-bottom-right-icons {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.uploaded-file-bottom-right-icons > a {
  color: #FFFFFF;
  text-decoration: none;
}

.uploaded-file-icon {
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1000;
}

.uploaded-file-container:hover .uploaded-file-icon {
  opacity: 1;
}

.uploaded-file-tag-icon > a {
  color: #C7CDD2;
  text-decoration: none;
}

.uploaded-file-tag-icon > a:hover {
  opacity: 1 !important;
}

.uploaded-file-icon-highlight {
  opacity: 1 !important;
  color: #27ae60 !important;
}

.uploaded-file-cover-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white !important;
}

.social-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.classic-item-link {
  text-decoration: none !important;
}

.classic-item-link:hover {
  text-decoration: none !important;
  //color: black;
}

.classic-item:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

//.classic-item:hover:after {
//  content: "";
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  background-color: rgba(0, 0, 0, 0.1);
//}

.channel-generic-icon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.channel-generic-icon > .channel-generic-social-icon {
  display: grid;
  place-items: center;
  position: absolute;
  bottom: -1px;
  right: -8px;
  background-color: #FFFFFF;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

.channel-select-card {
  cursor: pointer;
  position: relative;
}

.channel-select-card .text {
  font-size: 0.65em;
  overflow: hidden;
  text-overflow: "ellipsis";
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.channel-select-card:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
}

.channel-select-card .channel-select-card-check-icon {
  color: #2EB85C;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 5px;
}

.popover {
  max-width: 600px;
}

.youtube-video-embed {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.media-list-image-container {
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.media-list-image-container img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

/** Post List**/
.post-list {
  .post-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
  }
}

/** Calendar **/
.fc {
  --fc-button-text-color: #fff;
  --fc-button-bg-color: #0a58ca;
  --fc-button-border-color: #0a53be;
  --fc-button-hover-bg-color: #0b5ed7;
  --fc-button-hover-border-color: #0a58ca;
  --fc-button-active-bg-color: #0a58ca;
  --fc-button-active-border-color: #0a53be;

  .fc-popover-body {
    img {
      max-width: 100px;
    }
  }

  .event-block {
    color: black;
  }

  .fc-timegrid-event-harness {
    margin-top: 1px;
  }

  .fc-timegrid-body, .fc-daygrid-body {
    tr {
      height: 140px;
    }
  }

  .fc-day-sun, .fc-day-mon, .fc-day-tue,
  .fc-day-wed, .fc-day-thu, .fc-day-fri,
  .fc-day-sat, .fc-day-today, .fc-day-holiday {
    .fc-col-header-cell-cushion {
      text-decoration: none;
    }
  }

  .fc-dayGridMonth-view, .fc-timeGridDay-view {
    .fc-day-sun, .fc-day-mon, .fc-day-tue,
    .fc-day-wed, .fc-day-thu, .fc-day-fri,
    .fc-day-sat, .fc-day-today, .fc-day-holiday {
      .fc-col-header-cell-cushion {
        text-decoration: none;
        color: #000000;
        cursor: default;
      }
    }
  }

  // Calendar - month view
  .fc-dayGridMonth-view {
    .fc-daygrid-event {
      display: block;
    }

    .event-block {
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
    }

    .event-info {
      font-weight: bolder;
    }

    .social-svg-mask {
      fill: #FFFFFF !important;
    }

    .twitter-bg {
      background-color: #000000;

      .social-svg-icon {
        fill: #000000 !important;
      }
    }

    .facebook-bg {
      background-color: #3b5998;

      .social-svg-icon {
        fill: #3b5998 !important;
      }
    }

    .instagram-bg {
      background-color: #E1306C;

      .social-svg-icon {
        fill: #E1306C !important;
      }
    }

    .linkedin-bg {
      background-color: #0072b1;

      .social-svg-icon {
        fill: #0072b1 !important;
      }
    }

    .pinterest-bg {
      background-color: #E60022;

      .social-svg-icon {
        fill: #E60022 !important;
      }
    }

    .wordpress-bg {
      background-color: #1B769D;

      .social-svg-icon {
        fill: #1B769D !important;
      }
    }

    .tiktok-bg {
      background-color: #000000;

      .social-svg-icon {
        fill: #000000 !important;
      }
    }

    .youtube-bg {
      background-color: #4A89F6;

      .social-svg-icon {
        fill: #4A89F6 !important;
      }
    }

    .gmb-bg {
      background-color: #4A89F6;

      .social-svg-icon {
        fill: #4A89F6 !important;
      }
    }

    .telegram-bg {
      background-color: #23A2DF;

      .social-svg-icon {
        fill: #23A2DF !important;
      }
    }

    .bluesky-bg {
      background-color: #0285FF;

      .social-svg-icon {
        fill: #0285FF !important;
      }
    }

    .threads-bg {
      background-color: #080808;

      .social-svg-icon {
        fill: #080808 !important;
      }
    }

    .post-collection-bg {
      background-color: #27ae60;
    }
  }

  // Calendar - week view
  .fc-timeGridWeek-view {
    .event-block {
      width: 80%;

      img {
        width: auto;
        height: 100%;
      }
    }

    .event-info {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      width: 100%;
    }

    .event-time {
      width: 100%;
      font-weight: 900;
    }

    .event-account-name {
      font-weight: bold;
    }

    .event-text {
      margin-left: calc(0.5rem + 23px);
    }

    .event-account-name, .event-text {
      width: calc(100% - 23px - 0.5rem);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .event-thumbnail {
      width: 100%;
      height: 50%;
      text-align: center;
    }
  }

  // Calendar - day view
  .fc-timeGridDay-view {
    .event-block {
      width: 100%;

      img {
        width: auto;
        height: 100%;
      }
    }

    .event-info {
      display: flex;
      height: 100%;
      width: 100%;
    }

    .event-info-left {
      width: 70%;
    }

    .event-time {
      font-size: 1rem;
      width: 100%;
      font-weight: 900;
    }

    .event-account-name {
      font-size: 1rem;
      font-weight: bold;
      white-space: nowrap;
    }

    .event-text {
      margin-left: calc(0.5rem + 23px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .event-account-name, .event-text {
      width: calc(100% - 23px - 0.5rem);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .event-thumbnail {
      width: 30%;
      height: 100%;
      text-align: right;
    }
  }

  // .fc-timegrid-more-link {
  //   width: 9%;
  //   margin-top: 50px;
  //   text-align: center;
  //   padding-top: 30px;
  // }

  .fc-timeGridWeek-view, .fc-timeGridDay-view {
    .twitter-bg {
      .event-block {
        border-color: #000000;
      }
    }

    .facebook-bg {
      .event-block {
        border-color: #3b5998;
      }
    }

    .instagram-bg {
      .event-block {
        border-color: #E1306C;
      }
    }

    .linkedin-bg {
      .event-block {
        border-color: #0072b1;
      }
    }

    .pinterest-bg {
      .event-block {
        border-color: #E60022;
      }
    }

    .wordpress-bg {
      .event-block {
        border-color: #1B769D;
      }
    }

    .tiktok-bg {
      .event-block {
        border-color: #000000;
      }
    }

    .youtube-bg {
      .event-block {
        border-color: #FF0000;
      }
    }

    .gmb-bg {
      .event-block {
        border-color: #4A89F6;
      }
    }

    .telegram-bg {
      .event-block {
        border-color: #23A2DF;
      }
    }

    .bluesky-bg {
      .event-block {
        border-color: #0285FF;
      }
    }

    .threads-bg {
      .event-block {
        border-color: #080808;
      }
    }

    .post-collection-bg {
      .event-block {
        border-color: #27ae60;
      }
    }

    .event-block {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      color: #000000;
      border: 1px solid;
      border-left: 6px solid;
      border-radius: 5px;
    }
  }

}

.fc-theme-standard th {
  height: 2.5rem;
  vertical-align: middle !important;
}

.plan-header {
  font-weight: 700;
}

.plan-title {
  font-size: 1.25rem;
  color: #191919;
  letter-spacing: 0.5px;
}

.plan-button-block {
  .btn {
    font-weight: 700;
  }
}

.yearly-price {
  color: #ef4444;
}

.yearly-price-effect {
  background-image: linear-gradient(
      70deg,
      rgb(239, 68, 68, 1) 45%,
      #fff 50%,
      rgb(239, 68, 68, 1) 55%
  );
  background-size: 500% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: shine 2s;
  transform-origin: center;
}

.price-off, .one-time-payment {
  font-weight: 700;
  background-color: #22c55e;
  color: #f8fafc;
  padding: 0.18rem 0.3rem;
  border-radius: 0.2rem;
}

.one-time-payment {
  max-height: 2rem;
  white-space: nowrap;
  align-items: center;
}

.feature-list {
  font-weight: 300;
  font-size: 1.1rem;

  strong {
    font-weight: 700;
  }
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(153 153 153/var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(102 102 102/var(--tw-text-opacity));
}

@media screen and (max-width: 768px) {
  .media-gallery-widget-tab-container {
    overflow-y: scroll;
    height: 400px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .media-gallery-widget-tab-container {
    overflow-y: scroll;
    height: 500px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .media-gallery-widget-tab-container {
    overflow-y: scroll;
    height: 500px;
    margin-top: 30px;
  }
}

@keyframes shine {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.profile-avatar {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.profile-avatar > img {
  max-height: 70px;
}

.clickable-avatar {
  cursor: pointer;
}

.clickable-avatar:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.post-compose-tool-btn:hover {
  background-color: #4D5D69 !important;
}

.circle-cat {
  width: 15px;
  height: 15px;
  background-color: #ff5733;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.channel-select-container {
  max-height: 600px;
  padding-left: 5px;
  padding-right: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.youtube-thumbnail-container img {
  max-width: 100%;
}

.select-invalid-feedback {
  color: #e55353;
  font-size: 0.875em;
  margin-top: 0.25rem;
}

.react-select-icon {
  max-height: 30px;
}

.dropdown-menu {
  z-index: 9999 !important;
}

.footer {
  padding-right: 85px;
}

.btn-primary:hover {
  color: rgba(255, 255, 255, 0.87);
}

.classic-item input[type='checkbox'] {
  transform: scale(1.4); /* Adjust scale value as needed */
}

.modal {
  z-index: 9999999 !important;
}

.at-mention-in-text {
  font-weight: bold;
  color: #2980b9;
}

.uploadcare--powered-by, .uploadcare--powered-by__link {
  display: none;
}

.uploadcare--dialog_status_active {
  z-index: 9999998 !important;
}

.linkify-post-link {
  color: #4a90e2; /* Lighter blue for links */
  text-decoration: none; /* Optional: remove underline */
}

.linkify-post-link:hover {
  text-decoration: underline; /* Optional: underline on hover */
}

.linkify-post-hashtag {
  color: #4a90e2; /* Lighter blue for hashtags */
}

.linkify-post-mention {
  color: #4a90e2; /* Lighter blue for mentions */
}

.linkify-post-text-formatter {
  /* Add any additional formatting for the text container here if needed */
}
